$(function () {

    $('.browserInfos').html(platform.description);


    $( document ).ajaxStart(function() {
        $('.spinner').show();
    });

    $( document ).ajaxStop(function() {
        $('.spinner').hide();
    });



    /* Aktuell in Calc benutzt*/
    $(".js-mask-price-german-positive").inputmask("9{1,4},99");



    /*
    window.confirm = function(message){
         alertify.confirm('Confirm Title', message,
             function(){ alertify.success('Ok') }
            , function(){ alertify.error('Cancel')});
    };
    */

    alertify.defaults = {
        // dialogs defaults
        autoReset:true,
        basic:false,
        closable:true,
        closableByDimmer:true,
        frameless:false,
        maintainFocus:true, // <== global default not per instance, applies to all dialogs
        maximizable:true,
        modal:true,
        movable:true,
        moveBounded:false,
        overflow:true,
        padding: true,
        pinnable:true,
        pinned:true,
        preventBodyShift:false, // <== global default not per instance, applies to all dialogs
        resizable:true,
        startMaximized:false,
        transition:'',

        // notifier defaults
        notifier:{
            // auto-dismiss wait time (in seconds)
            delay:5,
            // default position
            position:'bottom-right'
        },

        // language resources
        glossary:{
            // dialogs default title
            title:'',
            // ok button text
            ok: 'OK',
            // cancel button text
            cancel: 'Abbrechen'
        },

        // theme settings
        theme:{
            // class name attached to prompt dialog input textbox.
            input:'ajs-input',
            // class name attached to ok button
            ok:'btn btn-danger',
            // class name attached to cancel button
            cancel:'btn btn-default'
        }
    };



    //iCheck for checkbox and radio inputs
    $('input[type="checkbox"], input[type="radio"]').iCheck({
        checkboxClass: 'icheckbox_square-orange',
        radioClass: 'iradio_square-orange'
    });

    $('#datatables-userlist').DataTable({
        "language": dt_lang_german,
        "paging": true,
        "lengthChange": true,
        "searching": true,
        "ordering": true,
        "info": true,
        "autoWidth": true,
        "columnDefs": [{
            "targets": 'no-sort',
            "orderable": false,
            "order": []
        }]

    });

    $('#datatables-customerlist').DataTable({
        "language": dt_lang_german,
        "paging": true,
        "lengthChange": true,
        "searching": true,
        "ordering": true,
        "order": [[ 5, "desc" ]],
        "info": true,
        "autoWidth": true,
        "serverSide": true,
        "processing": true,
        "ajax": {
            "url": "/programmverwaltung/customers/ajax"
        },
        "columnDefs": [{
            "targets": 'no-sort',
            "orderable": false,
            "order": []
        }]
    });

    //Maxlength
    $('textarea[maxlength],input[maxlength]').maxlength({
        alwaysShow: true,
        placement: 'bottom-right-inside',
        warningClass: "label label-success",
        limitReachedClass: "label label-danger",
        separator: ' von ',
        preText: '',
        postText: ' Zeichen'

    });




    // https://uxsolutions.github.io/bootstrap-datepicker
    //Date picker
    $('.js-datepicker').datepicker({
        language: 'de',
        autoclose: true,
        format: 'dd.mm.yyyy',


    });

    $('.js-datepicker-start').datepicker({
        language: 'de',
        autoclose: true,
        format: 'dd.mm.yyyy',

    }) .on('changeDate', function(e) {
        // `e` here contains the extra attributes
        // console.log(e);
        var $inputEnd = $(this).closest('tr').find('.js-datepicker-end');
        var d = e.date;
        $("#"+$inputEnd.attr('id')).datepicker('setStartDate',d);
    });

    /* So init. damit der DP dynmaisch gesetzt werden kann*/
    $('.js-datepicker-end').each(function() {

        $( "#"+$(this).attr('id') ).datepicker({

            language: 'de',
            autoclose: true,
            format: 'dd.mm.yyyy',

        });

    });









    //Initialize Select2 Elements
    $(".select2").select2();

    //Datemask dd/mm/yyyy
    $(".js-datemask").inputmask("dd.mm.yyyy", {"placeholder": "tt.mm.jjjj"});

    //Money Euro
    $("[data-mask]").inputmask();

    //Date range picker
    $('#reservation').daterangepicker();

    //Date range picker with time picker
    $('#reservationtime').daterangepicker({timePicker: true, timePickerIncrement: 30, format: 'MM/DD/YYYY h:mm A'});

    //Date range as a button
    $('#daterange-btn').daterangepicker(
        {
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            startDate: moment().subtract(29, 'days'),
            endDate: moment()
        },
        function (start, end) {
            $('#daterange-btn span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        }
    );


    $(document).on('click', '.js-show-new-field', function (e) {
        e.preventDefault();
        $('form .new-field').toggle();

    });

    $(document).on('click change', '.js-get-weekday-from-german-date', function (e) {
        e.preventDefault();
        setWeekdayForInputDateField($(this),$(this).closest('.input-group').find('.js-weekday'));

    });

    $(document).on('focus', 'input.js-select-on-focus', function (e) {
        e.preventDefault();
        $(this).select();

    });


    $( ".js-get-weekday-from-german-date" ).each(function(  ) {
        setWeekdayForInputDateField($(this),$(this).closest('.input-group').find('.js-weekday'));
    });


    /**
     * Programmvorschau
     */
    $(document).on('click', '.js-modal-preview', function (e) {
        var modal = $('#previewModal'), modalBody = $('#previewModal .modal-body');
        modalBody.html('');
        modal.on('show.bs.modal', function () {
            modalBody.load(e.currentTarget.href);
            }).modal();
        e.preventDefault();
    });

    /**
     * Programmpartner
     */
    $(document).on('click', '.js-modal-partner', function (e) {

        e.preventDefault();
        var modal = $('#partnerModal');
        var $frame = $('#partnerModal iframe');

        var selected = $(e.currentTarget).closest('.input-group').find('select').val();

        // e.currentTarget.href
        var src ;

        src =  "/programmverwaltung/partners/create";
        if (selected)
        {
            src = "/programmverwaltung/partners/"+selected+"/edit";
        }



        modal.on('show.bs.modal', function () {
            $frame.attr('src',src);

        }).modal();
        e.preventDefault();
    });


    /**
     * NEUE Kalkulation
     */
    $(document).on('click', '.js-create-new-calculation', function (e) {
        e.preventDefault();

        location.href = $(this).data('href')+'?year_id='+$('.js-create-new-calculation-year').val();

    });


    /**
     * Programmübersicht - Speicherung Onlinestatus (Checkbox)
     */

    $(document).on('ifChanged', '.jsChangeOnlineStatus', function (e) {

        var id = $(this).data('program-id');
        var status = e.target.checked ? 1:0;

        $.ajax({
            type: "POST",
            url: '/programmverwaltung/programs/'+ id +'/online-status/',
            data: {
                status:status
            }

        }).done(function () {

        })
        .fail(function () {

        });

    });



    /**
     * Programmübersicht - Speicherung Onlinestatus (Checkbox)
     */

    $(document).on('ifChanged', '.jsChangeSihotWebStatus', function (e) {

        var id = $(this).data('program-id');
        var status = e.target.checked ? 1:0;

        $.ajax({
            type: "POST",
            url: '/programmverwaltung/programs/'+ id +'/sihot-web-status/',
            data: {
                status:status
            }

        }).done(function () {

        })
            .fail(function () {

            });

    });


    /**
     * Programmübersicht - Sortierfunktion
     */
    if($("#programsorts").length)
    {
        $("#programsorts").sortable(
            {
                handle: '.doSort',

                update: function( event, ui ) {
                    $el = $("#programsorts").find('tr');
                    var data = [];
                    $el.each(function( index ) {
                        // console.log( index + ": "+ $(this).data('program-id'));
                        data.push($(this).data('program-id'))  ;
                    });
                    console.log(data);

                        $.ajax({
                        type: "POST",
                        url:  "/programmverwaltung/programs/update-program-sorts",
                        data: {programsort:data}

                    }).done( function() {



                    }).fail(function() {

                        console.log( "Ups - Fehler beim ordnen der Liste" );

                    });

                }
            }
        );
    }


    /**
     * Programm Typen Übersicht - Sortierfunktion
     */
    var programTypeSorts = $("#program-type-sorts");
    if(programTypeSorts.length)
    {
        programTypeSorts.sortable(
            {
                update: function( event, ui ) {
                    $el = programTypeSorts.find('tr');
                    var data = [];
                    $el.each(function( index ) {
                        data.push($(this).data('type-id'))  ;
                    });

                    $.ajax({
                        type: "POST",
                        url:  "/programmverwaltung/programs/types/update-sort",
                        data: {typesort: data}

                    }).done( function() {



                    }).fail(function() {

                        console.log( "Ups - Fehler beim ordnen der Liste" );

                    });

                }
            }
        );
    }



    // Inline Editing des kleien Mannes (z.B: Kategorie Preise).
    $(document).on('click', '.inline_edit:not(.justedit)', function (e) {

        var data;
        var url;
        var value;

        data = $(this).data('postdata');
        url = $(this).data('url');

        var $new = $('<input type="text"  class="form-control " style="width:80px;">').val($(this).data('value')).on("focusout keydown", function (e) {

            // FocusLost oder Return Key
            if (e.type == 'focusout' || (e.type == 'keydown' && e.keyCode == 13)) {
                var $el = $(this).closest('.inline_edit').html($(this).val()).data("value", $(this).val()).removeClass('justedit');
                data += "&value=" + $(this).val();

                $.ajax({
                    type: "POST",
                    url: url,
                    data: data

                }).done(function () {
                    //$el.append('<i class="pull-right fa fa-check bg-green"></i>').fadeIn();
                    $el.addClass('bg-success');
                })
                    .fail(function () {
                        $el.addClass('bg-danger');
                    });

            }

            // Tab
            if (e.type == 'keydown' && e.keyCode == 9) {
                e.preventDefault();
                /*
                if ($(this).closest('.inline_edit').next('.inline_edit').length) {
                    $(this).closest('.inline_edit').next('.inline_edit').click();
                }
                else {
                    $(this).closest('tr').next().find('.inline_edit:first').click();
                }
                */


                var c =  $(this).closest('td').prevAll().length - 2;
                var str = '.inline_edit:eq('+ c +')';

                $(this).closest('tr').next().find(str).click();

            }


        }).focus();

        $(this).html($new).addClass('justedit');
        $(this).find('input').focus().select();

    });



    // Bootstrap Tabs: Letzen Tab öffnen:
    reopenLastTab();

    // Text in Zwischenablage kopieren
    $('.copy-button').on('click', function ()
    {
        var $this = $(this);
        var range = document.createRange();
        range.selectNode( document.getElementById( $this.attr('data-target') ) );
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().empty();
        $this.stop().css("background-color", "#fff").animate({ backgroundColor: "#008cc7"}, 1500);
    });

}); // DOCREADY


function reopenLastTab()
{

    $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
        localStorage.setItem('lastTab', $(this).attr('href'));
    });


    //go to the latest tab, if it exists:
    var lastTab = localStorage.getItem('lastTab');
    if (lastTab) {
        $('a[href=' + lastTab + ']').tab('show');
    }
    else
    {
        // Set the first tab if cookie do not exist
        $('a[data-toggle="tab"]:first').tab('show');
    }
}

function addErrorToField(name) {
    $("input[name=" + name + "]").closest('.form-group').addClass('has-error');
}

function setEnddate($source)
{
    var val,date;
    val = $source.val();

    if(!$source.hasClass('js-startdate') || val == '')
    {
        return;
    }

    date = moment(val, "DD.MM.YYYY");

    if (date.isValid())
    {
       // $target.html(date.format('ddd'));
        // Vorherige Zeile finden und Differenz der beiden Datumswerte ermitteln


    }




}

function setWeekdayForInputDateField($source,$target)
{
    var val,date;
    val = $source.val();

    if (val == '')
    {
        return;
    }

    date = moment(val, "DD.MM.YYYY");

    if (date.isValid())
    {
        $target.html(date.format('ddd'));



    }
    else
    {
        $target.html('<i class="fa fa-exclamation-triangle text-red"></i>');
    }
}

