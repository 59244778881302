$(function () {



    $(document).on('click', '.js-modal-sihot-transfer', function (e) {
        var $modal = $('#sihotTransferModal');
        var $modalBody = $('#sihotTransferModal .modal-body');

        e.preventDefault();

        $modalBody.html('');
        $modal.modal();



        $(':checkbox.sihot:checked').each(function () {

            globalSihotTransfer.push($(this).val());

        });


        transfer();


    });



});

var globalSihotTransfer = [];
function transfer()
{
    var program_id = globalSihotTransfer.shift();

    if (!program_id)
    {
        showEnd();
        return;
    }

    var name = $('#js_program'+program_id).data('transferinfo');
    var url = '/programmverwaltung/sihot/'+ program_id +'/transfer';
    var $modalBody = $('#sihotTransferModal .modal-body');
    $modalBody.append('<div>Übertragung des Programms <b>'+name+'<b>:<div id="result'+program_id+'"></div></div>');

    // Wir wollen die Programme sequentiell abarbeiten (Wegen der Reihenfolge der IDs:), daher in always()
    // wieder Aufruf:
    $.ajax({
        type: "GET",
        url: url,
        dataType: 'json'
    }).done(function (msg) {

        if (msg.status == 'error')
        {
            $modalBody.find('#result'+program_id).append('<i class="fa fa-check bg-red"></i> Fehler: '+msg.message).fadeIn();
        }
        else
        {
            $modalBody.find('#result'+program_id).append('<i class="fa fa-check bg-green"></i> OK').fadeIn();
            $('#js_program'+program_id).iCheck('uncheck');
            $('#js_program'+program_id).prop('checked',false);
        }


    })
    .fail(function (msg) {
        $modalBody .append('<i class="fa fa-check bg-red"></i> Nicht erfolgreich. Interner Übertragungsfehler.').fadeIn();
    })
    .always(function(){
        transfer();
    });


}

function showEnd()
{
    var $modalBody = $('#sihotTransferModal .modal-body');
    $modalBody.append('<br><br><div class="alert alert-info">Programmübetragung beendet. Achten Sie auf Fehlemeldungen!</div>');
}